import React from 'react';
import { Key, Calendar, Clock, User, ArrowRight } from 'lucide-react';

const BlogPost = ({ title, excerpt, date, readTime, author, image, theme }) => (
  <div className="blog-card">
    <div className="blog-image" style={{ backgroundImage: `url(${image})` }}>
      <div className="blog-overlay" />
    </div>
    <div className="blog-content">
      <h2 className={`blog-title ${theme?.text || 'text-white'}`}>{title}</h2>
      <p className={`blog-excerpt ${theme?.textMuted || 'text-white/70'}`}>{excerpt}</p>
      <div className="blog-meta">
        <div className="meta-item">
          <Calendar size={16} />
          <span>{date}</span>
        </div>
        <div className="meta-item">
          <Clock size={16} />
          <span>{readTime} min read</span>
        </div>
        <div className="meta-item">
          <User size={16} />
          <span>{author}</span>
        </div>
      </div>
      <button className="blog-read-more">
        Read More
        <ArrowRight size={16} />
      </button>
    </div>
  </div>
);

const BlogPage = ({ theme }) => {
  const blogPosts = [
    {
      title: "The Art of Luxury Real Estate Photography",
      excerpt: "Discover how professional photography can transform your property listing and attract high-end buyers.",
      date: "Nov 15, 2023",
      readTime: 5,
      author: "Sarah Mitchell",
      image: "https://images.unsplash.com/photo-1600607687939-ce8a6c25118c?auto=format&fit=crop&w=800&q=80"
    },
    {
      title: "Investment Strategies in Premium Properties",
      excerpt: "Learn about the most effective strategies for investing in luxury real estate markets.",
      date: "Nov 12, 2023",
      readTime: 8,
      author: "Michael Chen",
      image: "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?auto=format&fit=crop&w=800&q=80"
    },
    {
      title: "Designing the Perfect Home Office",
      excerpt: "Create a productive and stylish workspace that adds value to your luxury home.",
      date: "Nov 10, 2023",
      readTime: 6,
      author: "Emma Watson",
      image: "https://images.unsplash.com/photo-1600494603989-9650cf6dad51?auto=format&fit=crop&w=800&q=80"
    },
    {
      title: "The Rise of Smart Homes",
      excerpt: "Explore how technology is revolutionizing luxury living and home automation.",
      date: "Nov 8, 2023",
      readTime: 7,
      author: "David Park",
      image: "https://images.unsplash.com/photo-1600566753376-12c8ab7fb75b?auto=format&fit=crop&w=800&q=80"
    }
  ];

  return (
    <div className="blog-page">
      <div className="blog-header">
        <div className="header-content">
          <div className="flex items-center space-x-3 mb-6">
            <Key className="gold-text" size={32} />
            <h1 className={`text-3xl font-light ${theme?.text || 'text-white'}`}>
              Insights & Perspectives
            </h1>
          </div>
          <p className={`text-xl ${theme?.textMuted || 'text-white/70'}`}>
            Explore the latest trends, insights, and expert advice in luxury real estate
          </p>
        </div>
      </div>
      
      <div className="blog-grid">
        {blogPosts.map((post, index) => (
          <BlogPost key={index} {...post} theme={theme} />
        ))}
      </div>

      <div className="blog-pagination">
        <div className="flex justify-center mt-12 space-x-4">
          <button className="glass-button px-6 py-3">
            Previous
          </button>
          <button className="glass-button px-6 py-3">
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default BlogPage;
