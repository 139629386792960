// File: App.js
import React, { useState, useEffect } from 'react';
import { Key, Menu, X, Home, Building, Sun, Moon } from 'lucide-react';
import BlogPage from './app/blog/page';
import './App.css';

const BusinessCard = ({ isVisible, onClose, isDarkMode, theme }) => {
  const [rotation, setRotation] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    const card = e.currentTarget;
    const rect = card.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    
    const centerX = rect.width / 2;
    const centerY = rect.height / 2;
    
    const rotateX = (y - centerY) / 20;
    const rotateY = (centerX - x) / 20;
    
    setRotation({ x: rotateX, y: rotateY });
  };

  const handleMouseLeave = () => {
    setRotation({ x: 0, y: 0 });
  };

  return (
    <div 
      className={`modal-overlay ${isVisible ? 'visible' : ''} ${isDarkMode ? 'dark' : 'light'}`}
      onClick={onClose}
    >
      <div 
        className="business-card"
        onClick={e => e.stopPropagation()}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        style={{
          transform: `perspective(1000px) rotateX(${rotation.x}deg) rotateY(${rotation.y}deg)`
        }}
      >
        <button 
          className="modal-close"
          onClick={onClose}
        >
          <X size={24} />
        </button>
        
        <div className="card-content">
          <div className="flex items-center space-x-4 mb-6">
            <Key className="gold-foil" size={32} />
            <div>
              <h2 className="text-2xl font-light gold-foil">Golden Key</h2>
              <p className="gold-foil opacity-80">Real Estate</p>
            </div>
          </div>
          <div className="space-y-4">
            <p className="gold-foil opacity-80">123 Business Avenue</p>
            <p className="gold-foil opacity-80">City, State 12345</p>
            <p className="gold-foil opacity-80">Phone: (555) 123-4567</p>
            <p className="gold-foil opacity-80">Email: contact@goldenkey.com</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const MobileContent = ({ theme }) => (
  <div className="content-card">
    <h3 className={`${theme.textMuted} text-xl mb-4`}>GOLDEN KEY REAL ESTATE</h3>
    <h1 className={`${theme.text} text-4xl sm:text-5xl lg:text-6xl font-light tracking-wider`}>
      UNLOCKING YOUR
      <span className="block">DREAM HOME</span>
    </h1>
    <p className={`${theme.textMuted} text-xl mt-6 mb-8 max-w-xl`}>
      Let us help you unlock the door to your perfect home. From first-time buyers to forever homes, we're here for every step of your journey.
    </p>
    <div className="space-y-4">
      <button className="mobile-cta-button mobile-cta-primary">
        <Home size={20} />
        I Want to Buy
      </button>
      <button className="mobile-cta-button mobile-cta-secondary">
        <Building size={20} />
        I Want to Sell
      </button>
    </div>
  </div>
);

const DesktopContent = ({ theme }) => (
  <div className="grid lg:grid-cols-2 gap-12">
    <div className="flex flex-col justify-center">
      <div className="space-y-8">
        <h3 className={`${theme.textMuted} text-xl`}>GOLDEN KEY REAL ESTATE</h3>
        <h1 className={`text-4xl sm:text-5xl lg:text-6xl font-light tracking-wider ${theme.text}`}>
          UNLOCKING YOUR
          <span className="block">DREAM HOME</span>
        </h1>
        <p className={`text-xl ${theme.textMuted} max-w-xl`}>
          Let us help you unlock the door to your perfect home. 
          From first-time buyers to forever homes, we're here for every step of your journey.
        </p>
      </div>
    </div>
    <div className="hidden lg:flex flex-col justify-center">
      <div className="luxury-home-container">
        <div className="glassmorphic-overlay">
          <div className="glass-content">
            <div className="space-y-8 text-center">
              <h2 className={`text-3xl font-light mb-6 ${theme.text}`}>
                Find Your <span className="gold-text">Perfect</span> Home
              </h2>
              <div className="grid grid-cols-2 gap-8">
                <button className="glass-button">
                  <div className="button-content">
                    <Home className="w-6 h-6 mb-2 gold-text" />
                    <span className={theme.text}>Browse Listings</span>
                  </div>
                </button>
                <button className="glass-button">
                  <div className="button-content">
                    <Building className="w-6 h-6 mb-2 gold-text" />
                    <span className={theme.text}>Sell Property</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const GoldenKeyGlass = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isCardVisible, setIsCardVisible] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [currentPage, setCurrentPage] = useState('home');

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    document.body.classList.toggle('light', !isDarkMode);
    document.body.classList.toggle('dark', isDarkMode);
  }, [isDarkMode]);

  const theme = {
    bg: isDarkMode ? 'bg-[#050505]' : 'bg-[#fafafa]',
    text: isDarkMode ? 'text-white' : 'text-zinc-900',
    textMuted: isDarkMode ? 'text-white/70' : 'text-zinc-600',
    accent: isDarkMode ? 'text-yellow-400' : 'text-yellow-600',
  };

  const leatherPattern = `
    <svg width="80" height="80" viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg">
      <pattern id="sewing-pattern" x="0" y="0" width="80" height="80" patternUnits="userSpaceOnUse">
        <rect width="80" height="80" fill="${isDarkMode ? '#050505' : '#fafafa'}"/>
        <path d="M0 40h80M0 0L40 40M80 0L40 40M0 80L40 40M80 80L40 40" 
              stroke="${isDarkMode ? 'rgba(255,255,255,0.13)' : 'rgba(0,0,0,0.13)'}"
              stroke-width="0.5"
              stroke-dasharray="2 2"/>
        <circle cx="40" cy="40" r="1" fill="${isDarkMode ? 'rgba(255,223,0,0.2)' : 'rgba(255,223,0,0.3)'}"/>
      </pattern>
      <rect width="80" height="80" fill="url(#sewing-pattern)"/>
    </svg>
  `;

  const handleNavigation = (page) => {
    setCurrentPage(page);
    setIsMenuOpen(false);
  };

  const renderContent = () => {
    switch (currentPage) {
      case 'blog':
        return <BlogPage theme={theme} />;
      default:
        return (
          <div className="flex-1 main-content">
            <div className="w-full max-w-[1920px] mx-auto px-6">
              {isMobile ? (
                <MobileContent theme={theme} />
              ) : (
                <DesktopContent theme={theme} />
              )}
            </div>
          </div>
        );
    }
  };

  return (
    <div className={`content-wrapper ${theme.bg}`}>
      <div 
        className="fixed inset-0 leather-pattern"
        style={{
          backgroundImage: `url("data:image/svg+xml;base64,${btoa(leatherPattern)}")`,
        }}
      />

      <div className="relative z-10 min-h-screen flex flex-col">
        {/* Header */}
        <div className="sticky top-0 w-full z-50 backdrop-blur-md bg-opacity-90">
          <div className="w-full max-w-[1920px] mx-auto px-6 py-6">
            <header className="flex items-center justify-between">
              <button 
                onClick={() => handleNavigation('home')}
                className="flex items-center space-x-3 group"
              >
                <Key className={`${theme.accent} transition-transform group-hover:rotate-12`} />
                <div>
                  <span className={`text-xl font-light tracking-wider ${theme.text}`}>GOLDEN KEY</span>
                  <span className={`block text-sm font-light tracking-wider ${theme.textMuted}`}>REAL ESTATE</span>
                </div>
              </button>
              
              <div className="flex items-center space-x-4">
                <button 
                  onClick={() => setIsDarkMode(!isDarkMode)}
                  className={`p-2 rounded-full ${theme.textMuted} hover:${theme.text} transition-colors`}
                >
                  {isDarkMode ? <Sun size={20} /> : <Moon size={20} />}
                </button>
                <button 
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                  className={`menu-hamburger ${isMenuOpen ? 'open' : ''} ${theme.textMuted} hover:${theme.text} transition-colors`}
                  aria-label="Toggle menu"
                >
                  {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
                </button>
              </div>
            </header>
          </div>
        </div>

        {/* Main Content */}
        {renderContent()}

        {/* Contact Button */}
        <div className="w-full max-w-[1920px] mx-auto px-6 py-6">
          {isMobile ? (
            <button 
              onClick={() => setIsCardVisible(true)}
              className="mobile-business-card"
            >
              <Key size={20} className="text-[#FFD700]" />
              <span>View Business Card</span>
            </button>
          ) : (
            <button 
              onClick={() => setIsCardVisible(true)}
              className="contact-button flex items-center space-x-2 px-6 py-3"
            >
              <div className="w-8 h-8 rounded-full overflow-hidden">
                <img 
                  src="/api/placeholder/32/32" 
                  alt="Agent" 
                  className="w-full h-full object-cover"
                />
              </div>
              <span className={theme.text}>CONTACT ME</span>
            </button>
          )}
        </div>
      </div>

      {/* Menu Overlay */}
      <div 
        className={`menu-overlay ${isMenuOpen ? 'open' : ''}`}
        onClick={() => setIsMenuOpen(false)}
      >
        <div 
          className="menu-content"
          onClick={e => e.stopPropagation()}
        >
          <nav className="mt-12 space-y-6">
            {[
              { label: 'Home', page: 'home' },
              { label: 'Properties', page: 'properties' },
              { label: 'Neighborhoods', page: 'neighborhoods' },
              { label: 'Blog', page: 'blog' },
              { label: 'Contact', page: 'contact' }
            ].map(({ label, page }) => (
              <button
                key={label}
                onClick={() => handleNavigation(page)}
                className={`block w-full text-left text-lg ${theme.textMuted} hover:${theme.text} transition-colors tracking-wider`}
              >
                {label}
              </button>
            ))}
          </nav>
        </div>
      </div>

      {/* Business Card Modal */}
      <BusinessCard 
        isVisible={isCardVisible}
        onClose={() => setIsCardVisible(false)}
        isDarkMode={isDarkMode}
        theme={theme}
      />
    </div>
  );
};

export default GoldenKeyGlass;
